<template>
  <router-view />
</template>

<script setup>
import { onMounted, ref } from "vue";
onMounted(() => {
  document.dispatchEvent(new Event("custom-render-trigger"));
});
</script>

<style>
body {
  margin: 0;
}
</style>
