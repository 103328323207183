import { createRouter, createWebHistory } from "vue-router";
import { isMobile } from "@/utils/libs";

const routes = [
  {
    name: "home",
    path: "/",
    meta: { title: "六合AI融合模型" },
    component: () => import("@/views/Home.vue")
  },
  {
    name: "mobile",
    path: "/mobile",
    meta: { title: "六合AI融合模型" },
    component: () => import("@/views/Mobile.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} `;
  }
  next();
});
router.onError((error) => {
  if (router.history) {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
      router.replace(targetPath);
    }
  }
});
export default router;
